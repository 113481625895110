import {
  CheckIcon,
  DeleteIcon,
  DragHandleIcon,
  EditIcon,
  InfoOutlineIcon,
} from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Image,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Staffs.css";
import Cookies from "js-cookie"
function Staffs() {
  // Loading variable
  const [is_submit_loading, set_submit_loading] = useState(false);
  // staff member image handling
  // ***************************
  const [uploadedImage, setUploadedImage] = useState(null);
  const [showServiceMenu, setShowServicesMenu] = useState(false);

  //get referance to first image uploader
  const hiddenFileInput1 = React.useRef(null);

  const [image1, setimage1] = useState(null); // For image preview
  const [file1, setFile1] = useState(null); // For storing the file to be uploaded
  const handleimage1Change = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile1(file); // Store file for later Cloudinary upload
      const reader = new FileReader();
      reader.onloadend = () => {
        setimage1(reader.result); // Show image preview
      };
      reader.readAsDataURL(file);
    }
  };

  // Clear the image
  const clearimage1 = () => {
    setimage1(null);
    setFile1(null);
    hiddenFileInput1.current.value = null;
  };

  //handle closing and opening modals (add,delete,update)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isStaffUpdateOpen,
    onOpen: onStaffUpdateOpen,
    onClose: onStaffUpdateClose,
  } = useDisclosure();
  const {
    isOpen: isStaffDeleteOpen,
    onOpen: onStaffDeleteOpen,
    onClose: onStaffDeleteClose,
  } = useDisclosure();

  // handle number Of checked services
  const [numberChecks, setNumberChecks] = useState(0);
  const [showCheck, setShowCheck] = useState([]);

  // References to inputs
  const staffNameRef = React.useRef();
  const staffOccupationRef = React.useRef();
  const [staffName, setStaffName] = useState("")
  const [staffOccupation, setStaffOccupation] = useState("")
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  // Variables
  const [refreshKey, setRefreshKey] = useState(0);
  const toast = useToast();
  const [nameRef, setNameRef] = useState(false);
  const [occupationRef, setOccupationRef] = useState(false);
  const [staffServiceId, setStaffServiceId] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [staffid, setStaffid] = useState("");
  const [currentStaff, setCurrentStaff] = useState();
  const [staffInfos, setStaffInfos] = useState(null);
  const [options, setOptions] = useState([]);
  const [services, setServices] = useState([]);
  const cancelRef = React.useRef();
  const [currentCloudinaryId, setCurrentCloudinaryId] = useState("")
  const [currentImageLink, setCurrentImageLink] = useState("")
  const [oldClouadinaryId, setOldClouadinaryId] = useState(-1)


  // Add selected options to list
  function handleSelectOption({ subservice }) {
    if (options[subservice.name]) {
      options[subservice.name] = null;
    } else {
      options[subservice.name] = subservice.id;
    }
    setOptions(options);
  }
  // verify if subservice is in the subservices assigned to the current staff
  const isInSubservices = (id) => {
    // setNumberChecks(currentStaff[0].services.length)
    for (let j in currentStaff[0].services) {
      if (currentStaff[0].services[j].serviceid === id) {
        return true;
      }
    }

    return false;
  };

  // Check all services already assigned to the staff in DB
  const setCheckServices = () => {
    services.map((service) => {
      service.subservices.map((subservice) => {
        if (isInSubservices(subservice.id)) {
          showCheck[subservice.name] = true;
          options[subservice.name] = subservice.id;
          setOptions(options);
        } else {
          showCheck[subservice.name] = false;
        }
      });

      setShowCheck(showCheck);
    });
  };

  // get current staff
  const getCurrentStaff = async (sid) => {
    try {
      const res = await axios.get("/staff/" + sid, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      setCurrentStaff(res.data);
      setNumberChecks(res.data[0].services.length);
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };
  // get all staffs
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/staff", {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": Cookies.get("authenticationToken")
          }
        });
        setStaffs(res.data);
      } catch (err) {
        if (err.response.status === 400) {
          toast({
            title: "Bad Request",
            description: "Missing token authorization",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 401) {
          toast({
            title: "Unauthorized Request",
            description: "Expired or Incorrect Token",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  // handle get All staffs when adding new staff member
  const handleGetServices = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.get("/service", {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      setServices(res.data);
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  // handle adding a new staff
  const handleSubmitStaff = async (e) => {
    e.preventDefault();
    onClose();
    set_submit_loading(true);
    if (staffName === "") {
      setNameRef(true);
      return 1;
    }
    setNameRef(false);
    if (staffOccupation === "") {
      setOccupationRef(true);
      return 1;
    }
    setOccupationRef(false);

    // make all variable empty in case they had previous in wanted data
    clearimage1();
    setShowServicesMenu([]);
    setShowCheck([]);
    // setOptions([]);
    setNumberChecks(0);
    setStaffServiceId([]);

    var key;
    var i = 0;
    setOptions(options);
    // create list of subservices ids that will be stored on the data base
    for (key in options) {
      if (options[key] === null) {
      } else {
        var a = options[key];
        staffServiceId[i] = a;
        i++;
      }
    }
    setStaffServiceId(staffServiceId);
    setOptions([]);

    //submit images to cloudinary
    const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/dxg9wvfwv/image/upload';
    const uploadPreset = 'ml_default';

    try {
      const imageData = await Promise.all(
        [file1].map(async (file) => {
          const image = new FormData();
          image.append('file', file); // file is the actual image data
          image.append('upload_preset', uploadPreset);
          image.append('folder', "staffs");

          const response = await fetch(cloudinaryUrl, {
            method: "post",
            mode: "cors",
            body: image
          });

          if (!response.ok) {
            throw new Error('Failed to upload image');
          }

          const json = await response.json();
          return {
            cloudinaryId: json.public_id,
            path: json.secure_url
          };
        })
      );

      const staffMember = {
        name: staffName,
        occupation: staffOccupation,
        imagelink: imageData[0].path,
        cloudinaryId: imageData[0].cloudinaryId,
        servicesid: staffServiceId
      }

      const res = await axios.post("/staff", staffMember, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });

      if (res.status === 200) {
        set_submit_loading(false);
        setStaffName("")
        setStaffOccupation("")
        onClose();
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Medarbejder tilføjet",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setUploadedImage(null);
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 404) {
        toast({
          title: "Upgrade to premium",
          description: "You can onlu have one staff for free subscribtion",
          position: "top-right",
          isClosable: true,
          status: "warning",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  // handle updating a new staff
  const handleUpdateStaff = async (e) => {
    e.preventDefault();
    onStaffUpdateClose();
    setCheckServices();
    if (staffName === "") {
      setNameRef(true);
      return 1;
    }
    setNameRef(false);
    if (staffOccupation === "") {
      setOccupationRef(true);
      return 1;
    }
    setOccupationRef(false);
    // make all variable empty in case they had previous inwanted data
    clearimage1();
    setShowServicesMenu([]);
    setStaffid(null);
    setShowCheck([]);
    setNumberChecks(0);
    var key;
    var i = 0;
    // setOptions(options);
    // create list of subservices ids that will be stored on the data base
    for (key in options) {
      if (options[key] === null) {
      } else {
        var a = options[key];
        staffServiceId[i] = a;
        i++;
      }
    }
    setStaffServiceId(staffServiceId);
    setOptions([]);

    //submit images to cloudinary
    const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/dxg9wvfwv/image/upload';
    const uploadPreset = 'ml_default';

    try {
      let imageData = null
      if (file1) {
        imageData = await Promise.all(
          [file1].map(async (file) => {
            const image = new FormData();
            image.append('file', file); // file is the actual image data
            image.append('upload_preset', uploadPreset);
            image.append('folder', "staffs");

            const response = await fetch(cloudinaryUrl, {
              method: "post",
              mode: "cors",
              body: image
            });

            if (!response.ok) {
              throw new Error('Failed to upload image');
            }

            const json = await response.json();
            return {
              cloudinaryId: json.public_id,
              path: json.secure_url
            };
          })
        );
      }
      else {
        imageData = [{
          cloudinaryId: currentCloudinaryId,
          path: currentImageLink
        }]
      }

      const staffMember = {
        name: staffName,
        occupation: staffOccupation,
        imagelink: imageData[0].path,
        cloudinaryId: imageData[0].cloudinaryId,
        servicesid: staffServiceId,
        oldCloudinaryId: oldClouadinaryId
      }

      let res = 0;

      res = await axios.put(`/staff/${staffid}`, staffMember, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        setRefreshKey((oldKey) => oldKey + 1);
        setStaffName("")
        setStaffOccupation("")
        toast({
          title: "Medarbejder opdateret",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setOldClouadinaryId(-1)
        onStaffUpdateClose();
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  // handle deleting staff
  const handleDeleteStaff = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.delete(`/staff/${staffid}`, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        onStaffDeleteClose();
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Medarbejder slettet.",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="Staffs">
        <div className="Header">Administrer dine medarbejdere</div>
        <div className="Addstaffcontainer">
          <Button
            className="Addstaffbtn"
            onClick={(e) => {
              onOpen();
              handleGetServices(e);
            }}
          >
            Tilføj medarbejder +
          </Button>
        </div>
        <div className="Stafflistcontainer">
          {staffs.length === 0 ? (
            <>
              <div className="emptyStaffs">
                <div className="Emptylistcontainer">
                  <InfoOutlineIcon className="Emptyicon" />
                  <div className="Emptyheader">Ingen medarbejder endnu !</div>
                  <div className="Emptytext">
                    Når du tilføjer en medarbejder, vises de her
                  </div>
                </div>
              </div>
            </>
          ) : (
            staffs.map((staff) => (
              <div key={staff.id} className="Stafflistitem">
                <img
                  src={
                    staff.image
                      ? staff.image
                      : "/assets/placeHolder/staff.jpg"
                  }
                  fallbackSrc="/assets/placeHolder/staff.jpg"
                  alt=""
                  className="Staffimg"
                ></img>
                <div className="Staffinfo">
                  <div className="Staffname">{staff.name}</div>
                  <div className="Staffocupation">{staff.occupation}</div>
                </div>
                <div className="Staffactions">
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<DragHandleIcon />}
                      variant="outline"
                      className="Accordiontbn"
                    />
                    <Portal>
                      <MenuList>
                        <MenuItem
                          icon={<EditIcon />}
                          onClick={async (e) => {
                            await getCurrentStaff(staff.id);
                            setShowCheck([]);
                            await handleGetServices(e);
                            setStaffid(staff.id);
                            setStaffInfos({ ...staff });
                            setStaffName(staff.name)
                            setStaffOccupation(staff.occupation)
                            setimage1(staff.image);
                            onStaffUpdateOpen();
                            setShowServicesMenu(false);
                            setNameRef(false);
                            setOccupationRef(false);
                            setOldClouadinaryId(null)
                            setCurrentCloudinaryId(staff.cloudinary_id)
                            setCurrentImageLink(staff.image)
                          }}
                        >
                          Redigere
                        </MenuItem>
                        <MenuItem
                          icon={<DeleteIcon />}
                          onClick={() => {
                            setStaffid(staff.id);
                            onStaffDeleteOpen();
                          }}
                        >
                          Slet
                        </MenuItem>
                      </MenuList>
                    </Portal>
                  </Menu>
                </div>
              </div>
            ))
          )}
        </div>

        {/* {ADD NEW STAFF MEMBER } */}
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setNameRef(false);
            setOccupationRef(false);
            clearimage1();
            setShowServicesMenu([]);
            setShowCheck([]);
            setOptions([]);
            setNumberChecks(0);
            hiddenFileInput1.current.value = null;
            setStaffName("")
            setStaffOccupation("")
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Opret din konto</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl className="Uploadimgcontainer">
                {/* Image Preview */}
                <Image
                  className="Image"
                  boxSize="100px"
                  objectFit="cover"
                  src={image1 || "/assets/placeHolder/staff.jpg"} // Show the uploaded image preview or fallback
                  alt="Image Preview"
                />

                {/* Hidden File Input */}
                <input
                  type="file"
                  ref={hiddenFileInput1}
                  onChange={handleimage1Change}
                  style={{ display: "none" }}
                />

                {/* Upload Image Button */}
                <Button
                  className="Uploadimg"
                  onClick={() => hiddenFileInput1.current.click()}
                >
                  Upload billede
                </Button>

                {/* Delete Image Button */}
                <div className="Deleteimg" onClick={clearimage1}>
                  Slet
                </div>

              </FormControl>

              <FormControl>
                <FormLabel>Fulde navn*</FormLabel>
                <Input
                  variant="filled"
                  style={nameRef ? { border: "red 2px solid" } : { border: "" }}
                  value={staffName}
                  onChange={(e) => { setStaffName(e.target.value); }}
                  required
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Beskæftigelse*</FormLabel>
                <Input
                  variant="filled"
                  style={
                    occupationRef ? { border: "red 2px solid" } : { border: "" }
                  }
                  value={staffOccupation}
                  onChange={(e) => { setStaffOccupation(e.target.value); }}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Service*</FormLabel>
                <button
                  className="selectServicesBtn"
                  onClick={() => setShowServicesMenu(!showServiceMenu)}
                >
                  <div>Vælg service</div> <div>{numberChecks}</div>
                </button>
                {showServiceMenu && (
                  <div className="popupCheckServices">
                    {() => setShowCheck([])}
                    {services.map((service) => (
                      <div key={service.id}>
                        <div className="selectServiceGrp">{service.name}</div>
                        <div>
                          {service.subservices.map((subservice) => (
                            <button
                              onClick={() => {
                                showCheck[subservice.name]
                                  ? (showCheck[subservice.name] =
                                    !showCheck[subservice.name])
                                  : (showCheck[subservice.name] = true);
                                setShowCheck(showCheck);
                                showCheck[subservice.name]
                                  ? setNumberChecks(numberChecks + 1)
                                  : setNumberChecks(numberChecks - 1);
                                handleSelectOption({ subservice });
                              }}
                              key={subservice.id}
                              className="checkServices"
                            >
                              {showCheck && showCheck[subservice.name] && (
                                <CheckIcon />
                              )}{" "}
                              {subservice.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              {is_submit_loading ? (
                <>
                  <Button
                    className="Modelbtn"
                    isLoading
                    loadingText="Loading"
                    mr={3}
                    type="submit"
                  >
                    Tilføj medarbejder
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="Modelbtn"
                    mr={3}
                    onClick={handleSubmitStaff}
                    type="submit"
                  >
                    Tilføj medarbejder
                  </Button>
                </>
              )}

              <Button
                onClick={() => {
                  onClose();
                  clearimage1();
                }}
              >
                Annullere
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* {UPDATE STAFF MEMBER } */}

        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isStaffUpdateOpen}
          onClose={() => {
            onStaffUpdateClose();
            setNameRef(false);
            setOccupationRef(false);
            clearimage1();
            setShowServicesMenu([]);
            setShowCheck([]);
            setStaffid(null);
            setOptions([]);
            setNumberChecks(0);
            hiddenFileInput1.current.value = null;
            setStaffName("")
            setStaffOccupation("")
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Opdater medarbejder</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl className="Uploadimgcontainer">
                <Image
                  className="Image"
                  boxSize="100px"
                  objectFit="cover"
                  src={
                    image1
                  }
                  fallbackSrc="/assets/placeHolder/staff.jpg"
                  alt="Dan Abramov"
                />
                <input
                  type="file"
                  ref={hiddenFileInput1}
                  onChange={handleimage1Change}
                  style={{ display: "none" }}
                />
                <Button
                  className="Uploadimg"
                  onClick={() => {
                    hiddenFileInput1.current.click()
                    setOldClouadinaryId(currentCloudinaryId)
                  }}
                >
                  Upload billede
                </Button>
                <div className="Deleteimg" onClick={() => { clearimage1(); setOldClouadinaryId(currentCloudinaryId);setCurrentCloudinaryId(null);setCurrentImageLink(null) }}>
                  Slet
                </div>
              </FormControl>

              <FormControl>
                <FormLabel>Fulde navn*</FormLabel>
                <Input
                  variant="filled"
                  required
                  value={staffName}
                  onChange={(e) => { setStaffName(e.target.value); }}
                  defaultValue={staffInfos ? staffInfos.name : ""}
                  style={nameRef ? { border: "red 2px solid" } : { border: "" }}
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Beskæftigelse*</FormLabel>
                <Input
                  variant="filled"
                  placeholder="Chef Barberer"
                  required
                  value={staffOccupation}
                  onChange={(e) => { setStaffOccupation(e.target.value); }}
                  defaultValue={staffInfos ? staffInfos.occupation : ""}
                  style={
                    occupationRef ? { border: "red 2px solid" } : { border: "" }
                  }
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Service*</FormLabel>
                <button
                  className="selectServicesBtn"
                  onClick={() => {
                    setShowServicesMenu(!showServiceMenu);
                    setCheckServices();
                  }}
                >
                  <div>Vælg service</div> <div>{numberChecks}</div>
                </button>
                {showServiceMenu && (
                  <div className="popupCheckServices">
                    {services.map((service) => (
                      <>
                        <div key={service.id} className="selectServiceGrp">
                          {service.name}
                        </div>
                        <div>
                          {service.subservices.map((subservice) => (
                            <button
                              onClick={() => {
                                showCheck[subservice.name] != null
                                  ? (showCheck[subservice.name] =
                                    !showCheck[subservice.name])
                                  : (showCheck[subservice.name] = true);
                                showCheck[subservice.name]
                                  ? setNumberChecks(numberChecks + 1)
                                  : setNumberChecks(numberChecks - 1);
                                setShowCheck(showCheck);
                                handleSelectOption({ subservice });
                              }}
                              key={subservice.id}
                              className="checkServices"
                            >
                              {showCheck[subservice.name] && <CheckIcon />}{" "}
                              {subservice.name}
                            </button>
                          ))}
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button className="Modelbtn" mr={3} onClick={handleUpdateStaff}>
                Opdater medarbejder
              </Button>
              <Button
                onClick={() => {
                  onStaffUpdateClose();
                  clearimage1();
                  setShowServicesMenu([]);
                  setShowCheck([]);
                  setOptions([]);
                  setNumberChecks(0);
                }}
              >
                Annullere
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* ALERT DIALOGUE TO DELETE STAFF */}
        <AlertDialog
          isOpen={isStaffDeleteOpen}
          leastDestructiveRef={cancelRef}
          onClose={onStaffDeleteClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Slet medarbejder
              </AlertDialogHeader>

              <AlertDialogBody>
                Er du sikker? Du kan ikke fortryde denne handling bagefter.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={() => {
                    onStaffDeleteClose();
                    clearimage1();
                  }}
                >
                  Annullere
                </Button>
                <Button colorScheme="red" onClick={handleDeleteStaff} ml={3}>
                  Slet
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </div>
    </>
  );
}

export default Staffs;
