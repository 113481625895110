import { Button, Switch, useToast } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Worksch.css";
import Cookies from "js-cookie"
function Worksch() {
  // Here because we are having just seven variables we will create them manualy instead of arrays to facilitate useState change listeners
  const [loading, isloading] = useState(false);
  const [DBtest, setDBtest] = useState(0);
  // Starting time
  const [StartMonday, setStartMonday] = useState(true);
  const [StartTuesday, setStartTuesday] = useState(true);
  const [StartWednesday, setStartWednesday] = useState(true);
  const [StartThursday, setStartThursday] = useState(true);
  const [StartFriday, setStartFriday] = useState(true);
  const [StartSaturday, setStartSaturday] = useState(true);
  const [StartSunday, setStartSunday] = useState(true);
  // Ending Time
  const [EndMonday, setEndMonday] = useState(true);
  const [EndTuesday, setEndTuesday] = useState(true);
  const [EndWednesday, setEndWednesday] = useState(true);
  const [EndThursday, setEndThursday] = useState(true);
  const [EndFriday, setEndFriday] = useState(true);
  const [EndSaturday, setEndSaturday] = useState(true);
  const [EndSunday, setEndSunday] = useState(true);
  // Switches
  const [switchMonday, setSwitchMonday] = useState(true);
  const [switchTuesday, setSwitchTuesday] = useState(true);
  const [switchWednesday, setSwitchWednesday] = useState(true);
  const [switchThursday, setSwitchThursday] = useState(true);
  const [switchFriday, setSwitchFriday] = useState(true);
  const [switchSaturday, setSwitchSaturday] = useState(true);
  const [switchSunday, setSwitchSunday] = useState(true);

  const [days, setDays] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const toast = useToast();
  // Get the saloon schedule
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/schedule", {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": Cookies.get("authenticationToken")
          }
        });
        var days = res.data;
        setDBtest(days.length);

        if (days.length === 0) {
          days = [
            {
              id: 24,
              day: "saturday",
              start: "00:00",
              end: "00:00",
              offday: 0,
              saloonid: 19,
            },
            {
              id: 23,
              day: "friday",
              start: "00:00",
              end: "00:00",
              offday: 0,
              saloonid: 19,
            },
            {
              id: 22,
              day: "thursday",
              start: "00:00",
              end: "00:00",
              offday: 0,
              saloonid: 19,
            },
            {
              id: 21,
              day: "wednesday",
              start: "00:00",
              end: "00:00",
              offday: 0,
              saloonid: 19,
            },
            {
              id: 20,
              day: "tuesday",
              start: "00:00",
              end: "00:00",
              offday: 0,
              saloonid: 19,
            },
            {
              id: 19,
              day: "monday",
              start: "00:00",
              end: "00:00",
              offday: 0,
              saloonid: 19,
            },
            {
              id: 18,
              day: "sunday",
              start: "00:00",
              end: "00:00",
              offday: 0,
              saloonid: 19,
            },
          ];
        }

        setDays(days);
        for (let i in days) {
          switch (days[i].day) {
            case "sunday": {
              setStartSunday(days[i].start);
              setEndSunday(days[i].end);
              setSwitchSunday(days[i].offday);
              break;
            }
            case "monday": {
              setStartMonday(days[i].start);
              setEndMonday(days[i].end);
              setSwitchMonday(days[i].offday);
              break;
            }
            case "tuesday": {
              setStartTuesday(days[i].start);
              setEndTuesday(days[i].end);
              setSwitchTuesday(days[i].offday);
              break;
            }
            case "wednesday": {
              setStartWednesday(days[i].start);
              setEndWednesday(days[i].end);
              setSwitchWednesday(days[i].offday);
              break;
            }
            case "thursday": {
              setStartThursday(days[i].start);
              setEndThursday(days[i].end);
              setSwitchThursday(days[i].offday);
              break;
            }
            case "friday": {
              setStartFriday(days[i].start);
              setEndFriday(days[i].end);
              setSwitchFriday(days[i].offday);
              break;
            }
            case "saturday": {
              setStartSaturday(days[i].start);
              setEndSaturday(days[i].end);
              setSwitchSaturday(days[i].offday);
              break;
            }
            default:
              break;
          }
        }
      } catch (err) {
        if (err.response.status === 400) {
          toast({
            title: "Bad Request",
            description: "Missing token authorization",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 401) {
          toast({
            title: "Unauthorized Request",
            description: "Expired or Incorrect Token",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  // Update Saloon Infos
  const handleUpdateDays = async (e) => {
    isloading(true);
    e.preventDefault();
    try {
      for (let i in days) {
        switch (days[i].day) {
          case "sunday": {
            days[i].start = StartSunday;
            days[i].end = EndSunday;
            days[i].offday = 1 * switchSunday;
            break;
          }
          case "monday": {
            days[i].start = StartMonday;
            days[i].end = EndMonday;
            days[i].offday = 1 * switchMonday;
            break;
          }
          case "tuesday": {
            days[i].start = StartTuesday;
            days[i].end = EndTuesday;
            days[i].offday = 1 * switchTuesday;
            break;
          }
          case "wednesday": {
            days[i].start = StartWednesday;
            days[i].end = EndWednesday;
            days[i].offday = 1 * switchWednesday;
            break;
          }
          case "thursday": {
            days[i].start = StartThursday;
            days[i].end = EndThursday;
            days[i].offday = 1 * switchThursday;
            break;
          }
          case "friday": {
            days[i].start = StartFriday;
            days[i].end = EndFriday;
            days[i].offday = 1 * switchFriday;
            break;
          }
          case "saturday": {
            days[i].start = StartSaturday;
            days[i].end = EndSaturday;
            days[i].offday = 1 * switchSaturday;
            break;
          }
          default:
            break;
        }
      }
      setDays(days);
      console.log(days);
      var day = [];
      var ends = [];
      var starts = [];
      var offdays = [];
      let j = 0;
      while (j < days.length) {
        day[j] = days[j].day;
        ends[j] = days[j].end;
        starts[j] = days[j].start;
        offdays[j] = days[j].offday;
        j++;
      }
      const data = {
        day: day,
        end: ends,
        start: starts,
        offday: offdays,
      };
      console.log(data);
      const res = await axios.put(`/schedule`, data, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Virksomheds tidsplan opdateret",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        isloading(false);
      }
    } catch (err) {
      isloading(false);
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  // Update Saloon Infos
  const handleAddDays = async (e) => {
    isloading(true);
    e.preventDefault();
    try {
      for (let i in days) {
        switch (days[i].day) {
          case "sunday": {
            days[i].start = StartSunday;
            days[i].end = EndSunday;
            days[i].offday = 1 * switchSunday;
            break;
          }
          case "monday": {
            days[i].start = StartMonday;
            days[i].end = EndMonday;
            days[i].offday = 1 * switchMonday;
            break;
          }
          case "tuesday": {
            days[i].start = StartTuesday;
            days[i].end = EndTuesday;
            days[i].offday = 1 * switchTuesday;
            break;
          }
          case "wednesday": {
            days[i].start = StartWednesday;
            days[i].end = EndWednesday;
            days[i].offday = 1 * switchWednesday;
            break;
          }
          case "thursday": {
            days[i].start = StartThursday;
            days[i].end = EndThursday;
            days[i].offday = 1 * switchThursday;
            break;
          }
          case "friday": {
            days[i].start = StartFriday;
            days[i].end = EndFriday;
            days[i].offday = 1 * switchFriday;
            break;
          }
          case "saturday": {
            days[i].start = StartSaturday;
            days[i].end = EndSaturday;
            days[i].offday = 1 * switchSaturday;
            break;
          }
          default:
            break;
        }
      }
      setDays(days);
      console.log(days);
      var day = [];
      var ends = [];
      var starts = [];
      var offdays = [];
      let j = 0;
      while (j < days.length) {
        day[j] = days[j].day;
        ends[j] = days[j].end;
        starts[j] = days[j].start;
        offdays[j] = days[j].offday;
        j++;
      }
      const data = {
        day: day,
        end: ends,
        start: starts,
        offday: offdays,
      };
      console.log(data);
      const res = await axios.post(`/schedule`, data);
      if (res.status === 200) {
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Virksomheds skema tilføjet",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        isloading(false);
      }
    } catch (err) {
      isloading(false);
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et par minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  return (
    <>
      <div className="Worksch">
        <div className="Daycontainer">
          <div className="Day">Mandag</div>
          <div className="Time">
            <div>Fra </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setStartMonday(e.target.value)}
                value={StartMonday}
              />
            </div>
            <div>til </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setEndMonday(e.target.value)}
                value={EndMonday}
              />
            </div>
          </div>
          <div className="Offdaycontainer">
            <div className="Offdaytxt">Fridag</div>
            <div className="Offdayradio">
              <Switch
                colorScheme="teal"
                id="offday"
                isChecked={switchMonday}
                onChange={() => setSwitchMonday(!switchMonday)}
              />
            </div>
          </div>
        </div>
        <div className="Daycontainer">
          <div className="Day">Tirsdag</div>
          <div className="Time">
            <div>Fra </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setStartTuesday(e.target.value)}
                value={StartTuesday}
              />
            </div>
            <div>Til </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setEndTuesday(e.target.value)}
                value={EndTuesday}
              />
            </div>
          </div>
          <div className="Offdaycontainer">
            <div className="Offdaytxt">Fridag</div>
            <div className="Offdayradio">
              <Switch
                colorScheme="teal"
                id="offday"
                isChecked={switchTuesday}
                onChange={() => setSwitchTuesday(!switchTuesday)}
              />
            </div>
          </div>
        </div>
        <div className="Daycontainer">
          <div className="Day">Onsdag</div>
          <div className="Time">
            <div>Fra </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setStartWednesday(e.target.value)}
                value={StartWednesday}
              />
            </div>
            <div>Til </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setEndWednesday(e.target.value)}
                value={EndWednesday}
              />
            </div>
          </div>
          <div className="Offdaycontainer">
            <div className="Offdaytxt">Fridag</div>
            <div className="Offdayradio">
              <Switch
                colorScheme="teal"
                id="offday"
                isChecked={switchWednesday}
                onChange={() => setSwitchWednesday(!switchWednesday)}
              />
            </div>
          </div>
        </div>
        <div className="Daycontainer">
          <div className="Day">Torsdag</div>
          <div className="Time">
            <div>Fra </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setStartThursday(e.target.value)}
                value={StartThursday}
              />
            </div>
            <div>Til </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setEndThursday(e.target.value)}
                value={EndThursday}
              />
            </div>
          </div>
          <div className="Offdaycontainer">
            <div className="Offdaytxt">Fridag</div>
            <div className="Offdayradio">
              <Switch
                colorScheme="teal"
                id="offday"
                isChecked={switchThursday}
                onChange={() => setSwitchThursday(!switchThursday)}
              />
            </div>
          </div>
        </div>
        <div className="Daycontainer">
          <div className="Day">Fredag</div>
          <div className="Time">
            <div>Fra </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setStartFriday(e.target.value)}
                value={StartFriday}
              />
            </div>
            <div>Til </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setEndFriday(e.target.value)}
                value={EndFriday}
              />
            </div>
          </div>
          <div className="Offdaycontainer">
            <div className="Offdaytxt">Fridag</div>
            <div className="Offdayradio">
              <Switch
                colorScheme="teal"
                id="offday"
                isChecked={switchFriday}
                onChange={() => setSwitchFriday(!switchFriday)}
              />
            </div>
          </div>
        </div>
        <div className="Daycontainer">
          <div className="Day">Lørdag</div>
          <div className="Time">
            <div>Fra </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setStartSaturday(e.target.value)}
                value={StartSaturday}
              />
            </div>
            <div>Til </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setEndSaturday(e.target.value)}
                value={EndSaturday}
              />
            </div>
          </div>
          <div className="Offdaycontainer">
            <div className="Offdaytxt">Fridag</div>
            <div className="Offdayradio">
              <Switch
                colorScheme="teal"
                id="offday"
                isChecked={switchSaturday}
                onChange={() => setSwitchSaturday(!switchSaturday)}
              />
            </div>
          </div>
        </div>
        <div className="Daycontainer">
          <div className="Day">Søndag</div>
          <div className="Time">
            <div>Fra </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setStartSunday(e.target.value)}
                value={StartSunday}
              />
            </div>
            <div>Til </div>
            <div>
              <input
                className="hourInput"
                type="time"
                onChange={(e) => setEndSunday(e.target.value)}
                value={EndSunday}
              />
            </div>
          </div>
          <div className="Offdaycontainer">
            <div className="Offdaytxt">Fridag</div>
            <div className="Offdayradio">
              <Switch
                colorScheme="teal"
                id="offday"
                isChecked={switchSunday}
                onChange={() => setSwitchSunday(!switchSunday)}
              />
            </div>
          </div>
        </div>
        {DBtest > 0 ? (
          <>
            {loading ? (
              <>
                <Button
                  className="Updatebtn"
                  disabled={true}
                  onClick={handleUpdateDays}
                >
                  Indlæser
                </Button>
              </>
            ) : (
              <>
                <Button className="Updatebtn" onClick={handleUpdateDays}>
                  Opdatering
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            {loading ? (
              <>
                <Button className="Updatebtn" disabled={true}>
                  Indlæser
                </Button>
              </>
            ) : (
              <>
                <Button className="Updatebtn" onClick={handleAddDays}>
                  Tilføje
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Worksch;
