import {
  File,
  ListFilter,
  MoreHorizontal,
  PlusCircle,
} from "lucide-react"
import {
  InfoOutlineIcon,
} from "@chakra-ui/icons";
import { Badge } from "../components/ui/badge"
import { Button } from "../components/ui/button"
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardFooter,
} from "../components/ui/card"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { Input } from "../components/ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs"
import { useEffect, useState } from "react"
import jsPDF from "jspdf";
import "jspdf-autotable";
import Cookies from "js-cookie"
import { Image, useToast } from "@chakra-ui/react";
import axios from "axios";

export const description =
  "An products dashboard with a sidebar navigation. The sidebar has icon navigation. The content area has a breadcrumb and search in the header. It displays a list of products in a table with actions."

export default function Products() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([])

  function formatDateTime(dateString) {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error("Invalid date:", dateString);
      return "Invalid Date";
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time

    return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
  }
  const toast = useToast()
  const [filters, setFilters] = useState({
    type: "all",
    search: "_",
    sort: "price",
    page: 1
  })
  const [pages, setPages] = useState(0)
  const [totalProducts, setTotalProducts] = useState(0)
  // Function to handle tab change
  const handleTabChange = (type) => {
    setFilters((prev) => ({
      ...prev,
      type, // Update the type value in filters
    }));
    handlePageChange(1)
  };
  // Function to handle input changes
  const handleSearchChange = (event) => {
    const value = event.target.value.trim();

    // Update the search value in filters, using "_" if the input is empty
    setFilters((prev) => ({
      ...prev,
      search: value === "" ? "_" : value,
    }));
    handlePageChange(1)
  };
  // Function to handle checkbox changes for the sort key
  const handleSortChange = (sortKey) => {
    setFilters((prev) => ({
      ...prev,
      sort: sortKey,  // Update the sort key
    }));
    handlePageChange(1)
  };
  // Function to handle page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= pages) {
      // Update the page in the filters state
      setFilters((prevFilters) => ({
        ...prevFilters,
        page: page, // Update the page
      }));
    }
  };


  const [refreshKey, setRefreshKey] = useState(true)

  // Get the list of products
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/products/${filters.type}/${filters.search}/${filters.sort}/${filters.page}`, {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": Cookies.get("authenticationToken")
          }
        });
        setProducts(res.data.products)
        setPages(res.data.totalPages)
        setTotalProducts(res.data.totalNumProducts)
      } catch (err) {
        if (err.response.status === 400) {
          toast({
            title: "Bad Request",
            description: "Missing token authorization",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 401) {
          toast({
            title: "Unauthorized Request",
            description: "Expired or Incorrect Token",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, refreshKey]);



  // handle deleting product
  const handleDeleteProduct = async (id) => {
    try {
      const res = await axios.delete(`/products/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 204) {
        setRefreshKey(!refreshKey);
        toast({
          title: "Produktet er slettet.",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  //prepare product's image to be added in pdf file
  async function getBase64ImageFromUrl(imageUrl) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  // export the table of product in pdf file 
  async function exportToPdf() {
    const doc = new jsPDF();
    const imageSize = 20; // Set the desired image size (width and height)

    const tableBody = await Promise.all(
      products.map(async (product) => {
        const imageBase64 = await getBase64ImageFromUrl(product.image_path);
        return [
          product.name,
          product.status,
          product.price,
          product.quantity,
          formatDateTime(product.created_at),
          imageBase64, // Store base64 image
        ];
      })
    );

    doc.autoTable({
      head: [["Navn", "Status", "Pris", "Mængde", "Oprettelsesdato", "Billede"]],
      body: tableBody.map(([name, status, price, quantity, createdAt, imageBase64]) => [
        name,
        status,
        price,
        quantity,
        createdAt,
        "" // Empty cell for the image
      ]),
      startY: 10, // Start position for the table
      rowPageBreak: 'auto',
      didDrawCell: function (data) {
        // Add image to the last column in the body rows
        if (data.column.index === 5 && data.cell.section === 'body') {
          const imageBase64 = tableBody[data.row.index][5];
          if (imageBase64) {
            doc.addImage(imageBase64, 'JPEG', data.cell.x + 2, data.cell.y + 2, imageSize, imageSize); // Place the image
          }
        }
      },
      styles: {
        cellPadding: 2, // Adjust padding
      },
      headStyles: {
        minCellHeight: 10, // Smaller header height
        valign: 'middle', // Vertically align header text
      },
      bodyStyles: {
        minCellHeight: imageSize + 4, // Ensure body rows have enough height for the image
        valign: 'middle', // Vertically center text in body rows
      },
    });

    doc.save("table_products.pdf");
  }


  return (
    <div className="Products flex min-h-screen w-full flex-col bg-muted/40" style={{ height: "100vh", backgroundColor: "#edeadf", overflowY: "scroll" }}>
      <div style={{ fontWeight: "bolder", fontSize: "larger", paddingTop: "1.5rem", paddingLeft: "1rem" }}>Produkter</div>
      <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">

        <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <Tabs defaultValue="all">
            <div className="flex items-center">
              <TabsList>
                <TabsTrigger
                  value="all"
                  onClick={() => handleTabChange("all")}
                  selected={filters.type === 'all'}
                >
                  Alle
                </TabsTrigger>

                <TabsTrigger
                  value="active"
                  onClick={() => handleTabChange("active")}
                  selected={filters.type === 'active'}
                >
                  Aktiv
                </TabsTrigger>
                <TabsTrigger
                  value="draft"
                  onClick={() => handleTabChange("draft")}
                  selected={filters.type === 'draft'}
                >
                  Kladde
                </TabsTrigger>
              </TabsList>
              <div className="ml-auto flex items-center gap-2">
                <div className="relative ml-auto flex-1 md:grow-0" style={{ marginRight: "10px" }}>
                  {/* <Search className="absolute left-2.5 top-3.5 h-4 w-4 text-muted-foreground" /> */}
                  <Input
                    type="search"
                    placeholder="Søg..."
                    className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
                    value={filters.search === "_" ? "" : filters.search} // Show empty input if search is "_"
                    onChange={handleSearchChange}
                  />
                </div>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" className="h-8 gap-1" style={{ marginRight: "10px" }}>
                      <ListFilter className="h-3.5 w-3.5" />
                      <span className="not-sr-only whitespace-nowrap">
                        Sortering
                      </span>
                    </Button>
                  </DropdownMenuTrigger >
                  <DropdownMenuContent align="end">
                    <DropdownMenuLabel>Sorter efter</DropdownMenuLabel>
                    <DropdownMenuSeparator />

                    <DropdownMenuCheckboxItem
                      checked={filters.sort === 'price'}
                      onCheckedChange={() => handleSortChange('price')}
                    >
                      Pris
                    </DropdownMenuCheckboxItem>

                    <DropdownMenuCheckboxItem
                      checked={filters.sort === 'quantity'}
                      onCheckedChange={() => handleSortChange('quantity')}
                    >
                      Mængde
                    </DropdownMenuCheckboxItem>

                    <DropdownMenuCheckboxItem
                      checked={filters.sort === 'category'}
                      onCheckedChange={() => handleSortChange('category')}
                    >
                      Kategori
                    </DropdownMenuCheckboxItem>

                    <DropdownMenuCheckboxItem
                      checked={filters.sort === 'created'}
                      onCheckedChange={() => handleSortChange('date')}
                    >
                      Dato
                    </DropdownMenuCheckboxItem>

                  </DropdownMenuContent>
                </DropdownMenu>
                <div style={{ marginRight: "3px" }}></div>
                <Button variant="outline" className="h-8 gap-1" style={{ marginRight: "10px" }} onClick={exportToPdf}>
                  <File className="h-3.5 w-3.5" />
                  <span className="not-sr-only whitespace-nowrap">
                    Eksport
                  </span>
                </Button>
                <div style={{ marginRight: "3px" }}></div>
                <Button className="h-8 gap-1" onClick={() => navigate("/bookingsystem/produkter/tilføj")}>
                  <PlusCircle className="h-3.5 w-3.5" />
                  <span className="not-sr-only whitespace-nowrap">
                    Tilføj Produkt
                  </span>
                </Button>
              </div>
            </div>
            {products.length === 0 ? (

              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div className="Emptylistcontainer" >
                  <InfoOutlineIcon className="Emptyicon" />
                  <div className="Emptyheader">Tom produktliste</div>
                  <div className="Emptytext">
                    Der er ingen produkter, der opfylder din forespørgsel
                  </div>
                </div>
              </div>

            ) : (
              <TabsContent value={filters.type}>
                <Card x-chunk="dashboard-06-chunk-0">

                  <CardContent>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead className="hidden w-[100px] sm:table-cell">
                            <span className="sr-only">Billede</span>
                          </TableHead>
                          <TableHead>Navn</TableHead>
                          <TableHead>Status</TableHead>
                          <TableHead className="hidden md:table-cell">
                            Pris
                          </TableHead>
                          <TableHead className="hidden md:table-cell">
                            Mængde
                          </TableHead>
                          <TableHead className="hidden md:table-cell">
                            Oprettet den
                          </TableHead>
                          <TableHead>
                            <span className="sr-only">Handlinger</span>
                          </TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {products.map((product, i) =>
                        (
                          <TableRow>
                            <TableCell className="hidden sm:table-cell">
                              <Image
                                src={product.image_path || "https://via.placeholder.com/64"}
                                alt="Product image"
                                height="64px"
                                objectFit="cover"
                              />
                            </TableCell>
                            <TableCell className="font-medium">
                              {product.name}
                            </TableCell>
                            <TableCell>
                              <Badge variant="outline">{product.status === "active" ? "Aktiv" : "Kladde"}</Badge>
                            </TableCell>
                            <TableCell className="hidden md:table-cell">
                              fra {product.price}
                            </TableCell>
                            <TableCell className="hidden md:table-cell">
                              {product.quantity}
                            </TableCell>
                            <TableCell className="hidden md:table-cell">
                              {formatDateTime(product.created_at)}

                            </TableCell>
                            <TableCell>
                              <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                  <Button
                                    aria-haspopup="true"
                                    size="icon"
                                    variant="ghost"
                                  >
                                    <MoreHorizontal className="h-4 w-4" />
                                    <span className="sr-only">Skift menu</span>
                                  </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end">
                                  <DropdownMenuLabel>Handlinger</DropdownMenuLabel>
                                  <DropdownMenuItem onClick={() => navigate(`/bookingsystem/produkter/rediger/${product.id}`)}>Rediger</DropdownMenuItem>
                                  <DropdownMenuItem onClick={() => handleDeleteProduct(product.id)}>Slet</DropdownMenuItem>
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                  <CardFooter>
                    <div className="text-xs text-muted-foreground" >
                      Viser <strong>1-{products.length > 10 ? 10 : products.length}</strong> of <strong>{totalProducts}</strong>{" "}
                      Produkter
                    </div>
                    <div className="pagination text-xs text-muted-foreground" style={{ width: "fit-content", margin: "auto" }}>
                      <button
                        onClick={() => handlePageChange(filters.page - 1)}
                        disabled={filters.page === 1}
                        className="pagination-button"
                        style={{ border: "2px #e2e8f0 solid", padding: "3px", borderRight: "0px", width: "60px" }}
                      >
                        Forrige
                      </button>

                      {/* Render page numbers dynamically */}
                      {/* {[...Array(pages)].map((_, index) => {
                        const pageNumber = index + 1;
                        return (
                          <button
                            key={pageNumber}
                            style={pageNumber < pages
                              ? {
                                border: "2px #e2e8f0 solid",
                                padding: "3px",
                                width: "24px",
                                borderRight: "0px",
                                backgroundColor: filters.page === pageNumber ? "#e2e8f0" : "white"
                              }
                              : {
                                border: "2px #e2e8f0 solid",
                                padding: "3px",
                                width: "24px",
                                backgroundColor: filters.page === pageNumber ? "#e2e8f0" : "white"
                              }
                            }

                            onClick={() => handlePageChange(pageNumber)}
                            className={`pagination-button ${filters.page === pageNumber ? 'active' : ''}`}
                          >
                            {pageNumber}
                          </button>
                        );
                      })} */}

                      {/* Render page numbers dynamically */}
                      {(() => {
                        const totalPages = pages; // Adjust this to your total number of pages
                        const currentPage = filters.page;

                        // Create an array to store the buttons or '...' dynamically
                        const pages_ = [];

                        // Handle case where there is only 1 page
                        if (totalPages === 1) {
                          pages_.push(
                            <button
                              key={1}
                              style={{
                                border: "2px #e2e8f0 solid",
                                padding: "3px",
                                width: "24px",
                                backgroundColor: currentPage === 1 ? "#e2e8f0" : "white"
                              }}
                              onClick={() => handlePageChange(1)}
                              className={`pagination-button ${currentPage === 1 ? 'active' : ''}`}
                            >
                              1
                            </button>
                          );
                          return pages_;
                        }

                        // Handle case where there are 2 pages
                        if (totalPages === 2) {
                          for (let i = 1; i <= 2; i++) {
                            pages_.push(
                              <button
                                key={i}
                                style={{
                                  border: "2px #e2e8f0 solid",
                                  padding: "3px",
                                  width: "24px",
                                  backgroundColor: currentPage === i ? "#e2e8f0" : "white"
                                }}
                                onClick={() => handlePageChange(i)}
                                className={`pagination-button ${currentPage === i ? 'active' : ''}`}
                              >
                                {i}
                              </button>
                            );
                          }
                          return pages_;
                        }

                        // Show first two pages if totalPages > 2
                        for (let i = 1; i <= 2; i++) {
                          pages_.push(
                            <button
                              key={i}
                              style={{
                                border: "2px #e2e8f0 solid",
                                padding: "3px",
                                width: "24px",
                                backgroundColor: currentPage === i ? "#e2e8f0" : "white"
                              }}
                              onClick={() => handlePageChange(i)}
                              className={`pagination-button ${currentPage === i ? 'active' : ''}`}
                            >
                              {i}
                            </button>
                          );
                        }

                        // Add '...' if current page is far from the second page
                        if (currentPage > 3) {
                          pages_.push(<span key="left-dots">...</span>);
                        }

                        // Always show the current page if it's not in the first two or last two pages
                        if (currentPage > 2 && currentPage < totalPages - 1) {
                          pages_.push(
                            <button
                              key={currentPage}
                              style={{
                                border: "2px #e2e8f0 solid",
                                padding: "3px",
                                width: "24px",
                                backgroundColor: "#e2e8f0"
                              }}
                              onClick={() => handlePageChange(currentPage)}
                              className="pagination-button active"
                            >
                              {currentPage}
                            </button>
                          );
                        }

                        // Add '...' if the current page is far from the last two pages
                        if (currentPage < totalPages - 2) {
                          pages_.push(<span key="right-dots">...</span>);
                        }

                        // Show last two pages if totalPages > 2
                        for (let i = totalPages - 1; i <= totalPages; i++) {
                          pages_.push(
                            <button
                              key={i}
                              style={{
                                border: "2px #e2e8f0 solid",
                                padding: "3px",
                                width: "24px",
                                backgroundColor: currentPage === i ? "#e2e8f0" : "white"
                              }}
                              onClick={() => handlePageChange(i)}
                              className={`pagination-button ${currentPage === i ? 'active' : ''}`}
                            >
                              {i}
                            </button>
                          );
                        }

                        return pages_;
                      })()}

                      <button
                        onClick={() => handlePageChange(filters.page + 1)}
                        disabled={filters.page === pages}
                        className="pagination-button"
                        style={{ border: "2px #e2e8f0 solid", padding: "3px", borderLeft: "0px", width: "60px" }}
                      >
                        Næste
                      </button>
                    </div>
                  </CardFooter>
                </Card>
              </TabsContent>
            )}
          </Tabs>
        </main>
      </div>
    </div>
  )
}