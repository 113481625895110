import axios from "axios";
import Cookies from "js-cookie";


// Function to check if the user is allowed to access the authentication routes via main "token"
export async function checkAuthentication() {
  try {
    const token = Cookies.get("authenticationToken");
    if (token) {
      const response = await axios.get("/saloon/validate", {
        headers: {
          Authorization: token,
        },
      });
    
      if (response.status === 204) {
        return true;
      } else {
        Cookies.remove("authenticationToken");
        return false;
      }
    }else{
        return false
    }
  } catch (error) {
    if (error.response.status === 400) {
      return false;
    }
    if (error.response.status === 401) {
      Cookies.remove("authenticationToken");
      console.log("he")
      return false
    }
    if (error.response.status === 500) {
      return false;
    }

  }

}




