import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDfuzLa7SdXm0A8RkGbQt5NPTlyDXKliuA",
  authDomain: "flatpay-integration.firebaseapp.com",
  projectId: "flatpay-integration",
  storageBucket: "flatpay-integration.firebasestorage.app",
  messagingSenderId: "182875060065",
  appId: "1:182875060065:web:bcc64e128d23bba35ab720"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
