import { Button, Radio, RadioGroup, Stack, Textarea, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import "./Cancelaccount.css";
import Cookies from "js-cookie"
import axios from "axios";

function Cancelaccount() {
  const toast=useToast()
  const [showTextarea, setShowTextarea] = useState(false);
  const handleOptionChange = (value) => {
    if (value === "3") {
      setShowTextarea(true);
    } else {
      setShowTextarea(false);
    }
  };


  const closeAccount = async () => {
    try {
      const res = await axios.get("/saloon/disable", {
        headers: {
          'Content-Type': 'application/json',
          "Authorization":Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        /****setting data for the cards****/
        Cookies.delete("authenticationToken")
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    
    }
  };
  return (
    <>
      <div className="Cancelaccount">
        <div className="Header">Lukning af konto</div>
        <div className="Subheader">Hvorfor vil du slette din konto?</div>
        <div className="Reason">
          <RadioGroup defaultValue="2" onChange={handleOptionChange}>
            <Stack>
              <Radio size="md" value="1" colorScheme="green">
                Jeg får for mange e-mails fra Bookinghero.io <br />
                Hvis du foretrækker at beholde dine kontofordele uden at få
                e-mailmarkedsføring, kan du framelde dig i stedet.
              </Radio>
              <Radio size="md" value="2" colorScheme="green">
                Jeg vil bruge en anden e-mailadresse til min konto <br /> Det
                kan du gøre ved at ændre den herunder eller opdatere den når som
                helst under &quot;Personlige oplysninger&quot;.
              </Radio>

              <Radio size="md" value="3" colorScheme="green">
                Andet <br /> Har du noget feedback du vil sende til os? Vi
                bruger din feedback til at løse problemer og forbedre vores
                tjenester.
              </Radio>
            </Stack>
          </RadioGroup>
        </div>
        {showTextarea && (
          <div className="Comment">
            <Textarea />
          </div>
        )}
        <Button className="Updatebtn">Sendt</Button>
      </div>
    </>
  );
}

export default Cancelaccount;
