import { Divider } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import "./Settingsleftbar.css";

function Settingsleftbar() {
  return (
    <>
      <div className="Settingsleftbar">
        <Link className="Settingslink" to="generelinformation">
          Generel information
        </Link>
        <Link className="Settingslink" to="kontaktinfo">
          Kontaktinformation
        </Link>
        <Link className="Settingslink" to="album">
          Billeder
        </Link>
        <Link className="Settingslink" to="arbejdsplan">
          Arbejdsplan
        </Link>
        <Link className="Settingslink" to="placering">
          Placering
        </Link>
        <Link className="Settingslink" to="sikkerhed">
          Sikkerhed
        </Link>
        <Link className="Settingslink" to="support">
          Support
        </Link>
        <Divider className="Settingsdevider" />
        <Link to="annuller" className="Settingscancel">
          Luk konto
        </Link>

      </div>
    </>
  );
}

export default Settingsleftbar;
