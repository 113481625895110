import React from "react";


function MobilePage() {
    return (

        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",height:"fit-content",alignSelf:"center" }}>
            <img style={{marginTop:"40%", width: "200px", height: "80px" }} src="/assets/Icons/google.png" alt="" />
            <img style={{ width: "175px", height: "60px" }} src="/assets/Icons/apple.png" alt="" />
        </div>

    );
}

export default MobilePage;