import { Button, Input, Textarea, useToast } from "@chakra-ui/react";
import emailjs from "@emailjs/browser";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./Support.css";

function Support() {
  const toast = useToast();

  // keep track of the saloon object
  const [name, setName] = useState();
  const [email, setEmail] = useState();

  const formRef = useRef();
  // Get the saloon general infos
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const res = await axios.get("/saloon/general");
        // setName(res.data.general_settings[0].name);
        // const res2 = await axios.get("/saloon/contact");
        // setEmail(res2.data.email);
      } catch (err) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et par minutter",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_v12ocoa",
        "template_8oj8vjb",
        e.target,
        "AZGgEjALEGA1sc0t6"
      )
      .then(
        (result) => {
          toast({
            title: "E-mail blev sendt",
            description: "Vi vender tilbage til dig, så hurtigt som muligt",
            position: "top-right",
            isClosable: true,
            status: "success",
          });
        },
        (error) => {
          toast({
            title: "din e-mail blev ikke sendt",
            description: "prøv venligst senere",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      );
  };

  return (
    <>
      <form className="Support" ref={formRef} onSubmit={sendEmail}>
        <div className="Supportheader">
          Hvis du oplever problemer, er du velkommen til at kontakte os
        </div>
        <div className="Objectcontainer">
          <div>Emne</div>
          <Input variant="filled" name="subject" required />
        </div>
        <div className="Messagecontainer">
          <div>Besked</div>
          <Textarea
            variant="filled"
            className="Textarea"
            name="message"
            required
          />
        </div>
        <Input variant="filled" name="saloon" value={name} display={"none"} />
        <Input variant="filled" name="email" value={email} display={"none"} />
        <Button className="Updatebtn" type="submit">
          Sendt
        </Button>
      </form>
    </>
  );
}

export default Support;
