import React, { useContext, useEffect, useState } from "react";
import "./Loginleft.css";
import { Link, useNavigate } from "react-router-dom";
import {
  background,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

import axios from "axios";
import Cookies from "js-cookie"
function Loginleft() {
  // call loginsaloon function from state and return response
  // get current user to check if saloon is already connected or not

  // handle animation for loading requests
  const [isLoading, setisLoading] = useState(false);

  // handle naviguation between pages
  const navigate = useNavigate();

  // declare toast for notifications
  const toast = useToast();

  // set show and hide password
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  // store data from inputs
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });


  // handle setting cookies
  function setCookiePromise(key, value, options) {
    return new Promise((resolve, reject) => {
      try {
        Cookies.set(key, value, options);
        resolve("Cookie set successfully");
      } catch (error) {
        reject(error);
      }
    });
  }


  // handle getting the data from the inputs
  const handlechange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // handle submitting the form
  const handlesubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);
    try {
      if (inputs.email === "" || inputs.password === "") {
        toast({
          title: "Skriv fejl",
          description: "E-mail- eller adgangskodefeltet er tomt",
          position: "top-right",
          isClosable: true,
          status: "warning",
        });
        setisLoading(false);
      } else {

        const res = await axios.post("/saloon/login", inputs, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (res.status === 200) {

          setCookiePromise("authenticationToken", res.data.token, {
            expires: 365,
          })
            .then(() => {
              window.location.href = "/bookingsystem/kalender";
              setisLoading(false);
            })
            .catch((error) => {
              console.error("Error:", error);
            });

        }
      }

    } catch (err) {
      if (err.response.status === 404) {
        toast({
          title: "Login fejl",
          description: "Email not registred",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Login fejl",
          description: "Incorrect Password",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      setisLoading(false);
    }
  };


  return (
    <>
      <div className="Loginleft">
        <Link to="/">
          <img
            src="../assets/Logo/park_logo.png"
            alt=""
            className="Logocontainer"
          ></img>
        </Link>
        <div className="Header">Velkommen tilbage !</div>
        <div className="Subheader">
          Indtast venligst dine oplysninger for at logge ind.
        </div>
        <form>
          <div className="Label">E-mail</div>
          <Input
            name="email"
            required
            variant="filled"
            placeholder="Indtast din email"
            size="lg"
            className="Emailinput"
            type="email"
            _hover={{background:"#edeadf",border:"black solid 1px",borderRadius:0}}
            _focus={{background:"#edeadf",border:"black solid 1px",borderRadius:0}}
            onChange={handlechange}
          />
          <div className="Passlabel">Adgangskode</div>
          <InputGroup className="Passinput">
            <Input
              name="password"
              required
              type={show ? "text" : "password"}
              placeholder="Indtast din adgangskode"
              variant="filled"
              className="Password"
              _hover={{background:"#edeadf",border:"black solid 1px",borderRadius:0}}
              _focus={{background:"#edeadf",border:"black solid 1px",borderRadius:0}}
              onChange={handlechange}
            />
            <InputRightElement width="4.5rem">
              <Button
                onClick={handleClick}
                className="ShowHide"
                background={"none"}
                _hover={{background:"none"}}
                _focus={{background:"none"}}
              >
                {show ? <FaRegEyeSlash /> : <FaRegEye />}
              </Button>
            </InputRightElement>
          </InputGroup>
          <div className="Fergotpass">
            <Link to="/booking-system/fergotpassword">
              Glemt kodeord ?
            </Link>
          </div>
          {!isLoading ? (
            <>
              <Button className="Submitbtn" onClick={handlesubmit}>
                Log ind
              </Button>
            </>
          ) : (
            <>
              <Button className="Submitbtn" isLoading loadingText="Indlæser">
                Log ind
              </Button>
            </>
          )}
        </form>
      </div>
    </>
  );
}

export default Loginleft;
